import React from "react"

import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaCcPaypal } from "@react-icons/all-files/fa/FaCcPaypal"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 100%;
  background-color: rgb(196, 195, 195);
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  

`
const Title = styled.h4`
`
const SubTitle = styled.p`
font-size:small;
`

const ListSyles = styled.ul`
  list-style: none;
  margin: auto;
`
const Item = styled.li`
  display: inline-block;
  height: 100px;
  padding: 0 40px;
`
const Youtube = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  margin-top: 10px;
  &:hover {
    color:  rgb(221, 75, 57);
  }
`
const Facebook = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  margin-top: 10px;
  &:hover {
    color:  rgb(66, 103, 178);
  }
`
const Paypal = styled.a`
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin-top: 10px;
  &:hover {
    color:  rgba(23, 155, 215, 1);
  }
`
const Donations = styled.div`
color:  #0d4b68;
&:hover ${Paypal}  {
    color:  rgba(23, 155, 215, 1);

  }
  margin:auto;
  text-align: center;
  
`;

const LinkIcon = styled.a`
  text-decoration: none;
  width: 200px;
  margin: auto;
  font-size: 30px;
  &:hover {
    color: #ca0388;
    text-decoration: underline;
  }
`
export default function Footer() {
 
  return (
    <Wrapper>
      <Title>The School of The Natural Order of Colorado</Title>
      <SubTitle>Follow us on Social Media or visit The School of the Natural Order, Nevada Chapter!</SubTitle>
      <ListSyles>
        <li>
          
            
            <LinkIcon
            href="https://www.sno.org/"
            target="_blank"
            rel="noopener noreferrer"
          ><h5>
                <strong>sno.org</strong>
              </h5>
              <h6 className="link-item">
                <strong>Nevada</strong>
              </h6>
          </LinkIcon>
           
        </li>
        <Item>
          <a
            href="https://www.facebook.com/School-of-the-Natural-Order-Colorado-499911493409876/"
            target="_blank"
            rel="noopener noreferrer"
            className="facebook"
          >
            <Facebook>
              <FaFacebook className="fa-2x" />
            </Facebook>
          </a>
        </Item>
        <Item>
          
            <Paypal href="https://www.paypal.com/paypalme/SNOofColorado">
            <FaCcPaypal size="80px"/>

            </Paypal>
            
        </Item>
        <Item>
          <a
            href="https://www.youtube.com/channel/UCHKIeVeZA3O9Zmnm27Ywddg"
            target="_blank"
            rel="noopener noreferrer"
            className="youtube"
          >
            <Youtube>
              <FaYoutube />
            </Youtube>
            {/* <FontAwesomeIcon icon={faYoutube} className="fa-2x" /> */}
          </a>
        </Item>
        <Donations>Donations accepted through PayPal</Donations>
      </ListSyles>
      <p className="text-center">
        {" "}
        &#x00A9; 2020 The School of Natural Order of Colorado
      </p>
      
    </Wrapper>
  )
}
